import InputLayout from "../../../../atoms/InputLayout";
import PageCard from "../../../../atoms/PageCard";

const PreferencesNotes = ({ data }) => {
  console.log("data", data);

  return (
    <>
      <h3 className="h3-j mb-3">
        {data?.length === 1 ? "Preferences Note" : "Preferences Notes"}
      </h3>

      {data?.length > 0 &&
        data.map((item: any) => (
          <PageCard
            key={item?.id || Math.random()} // Add a unique key for each element
            customStyles={{ backgroundColor: "#F9F9F9" }}
            classes="p-4 mb-4"
          >
            <InputLayout>
              <div className="p-dark-product-company">Title</div>
              <div className="p-light-small-product-company">
                {item?.title || "--"}
              </div>
            </InputLayout>

            <InputLayout classes="mt-4">
              <div className="p-dark-product-company">Note</div>
              <div className="p-light-small-product-company">
                {item?.notes || "--"}
              </div>
            </InputLayout>
          </PageCard>
        ))}
    </>
  );
};

export default PreferencesNotes;
