import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getData } from "../../../../services";
import { loaderData } from "../../../../store/slices/loaderSlice";
import SelectDropdown from "../../../atoms/Dropdown";
import Heading from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import BlogFilters from "../../../organisms/blogFilters/index";
import BlogTemplate from "../../../templates/BlogTemplate";
import ShimmerResources from "../Resource/Shimmer/shimmer";
import Helper from "./Helper";
import "./library.scss";

const Library = () => {
  const [cookies] = useCookies(["t"]);
  const { search_query } = useParams();
  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);
  const dispatch = useDispatch();
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [resourceData, setReourceData] = useState({
    data: [],
    total_record: 0,
  });
  const [dataLoading, setDataLoading] = useState(false);
  const [categories, setCategories] = useState<any>(); // Use string[] for categories
  const [selectedCompany, setSelectedCompany] = useState<any>("");
  const [isSearched, setIsSearched] = useState(false);
  const [pageLimit, setPageLimit] = useState(9);
  const loadingDataValue = useSelector(loaderData);
  const { filterData, companyList } = Helper();

  const getResourceData = useCallback(async () => {
    try {
      setDataLoading(true);

      const res = await getData({
        endpoint: "Blog/customer/getAllLibraryBlogs",
        params: {
          search_query: searchedValue,
          categories: JSON.stringify(categories),
          company_id: selectedCompany,
          page: 1,
          page_limit: pageLimit,
        },
        token: cookies.t,
      });

      if (res) {
        setTimeout(() => {
          setDataLoading(false);
        }, 50);

        setReourceData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, categories, cookies.t, selectedCompany, pageLimit]);

  useEffect(() => {
    // if (!search_query) {
    getResourceData();
    // }
  }, [getResourceData]);

  const handleSearchEmit = (searchValue: string) => {
    setIsSearched(true);
    setSearchedValue(searchValue);
  };

  const handleFilterSelect = (option: string) => {
    setIsSearched(true);
    if (option === "All") {
      setCategories(null);
    } else {
      setCategories([option]); // Use spread operator to correctly update state
    }
  };

  const loadMore = () => {
    setPageLimit((prev) => prev + 9);
  };

  return (
    <div id="Library-Container">
      {/* {isLoading && <Loader />} */}
      <div className="resource-container">
        <Heading title="Library" type="main">
          <Search onSearchEmit={handleSearchEmit} placeholder="Search" />
        </Heading>

        <div className="d-flex align-items-center justify-content-between library-container-dropdowm">
          <BlogFilters
            data={filterData}
            onFilterSelect={handleFilterSelect}
            alreadySelected={search_query}
          />

          <SelectDropdown
            data={companyList}
            placeholder="Select Company"
            selectedValue="All Company"
            onChange={(value) => {
              setIsSearched(true);
              if (value === "All Company") {
                setSelectedCompany(null);
              } else {
                setSelectedCompany(value);
              }
            }}
            type="filter"
          />
        </div>
        {dataLoading ? (
          <ShimmerResources />
        ) : (
          <>
            <BlogTemplate
              data={resourceData?.data}
              type="Library"
              isSearched={isSearched}
            />

{userInfo?.investment_library_education && 
            <div className="load-more">
              {resourceData.total_record > pageLimit && (
                <button
                  className="btn btn-outline-dark btn-sm"
                  onClick={() => loadMore()}
                >
                  Load More
                </button>
              )}
            </div>
}
          </>
        )}
      </div>
    </div>
  );
};

export default Library;
