import { useSubscription } from ".";
import "../components/molecules/Modal/LogoutModal/logout-modal.scss";

import { useSelector } from "react-redux";
import CloseButton from "../components/atoms/CloseButton";
import ConfirmationCheck from "../components/Icons/ConfirmationCheck";


const TrialActivationModal = () => {
  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;

  const { isTrialActivationModalVisible, setIsTrialActivationModalVisible } =
    useSubscription();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);



  return (
    <div
      className={`modal ${isTrialActivationModalVisible ? "active" : ""}`}
      // style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content">
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            zIndex: 10,
          }}
          className="cursor-pointer"
        >
          <CloseButton
            onClose={(e) => {
              e.stopPropagation();
              setIsTrialActivationModalVisible(false);
            }}
          />
        </div>

        <div className="modal-body py-4">
          <div className="d-flex align-items-center justify-content-center flex-column gap-2">
            <ConfirmationCheck />
            {/* <p className="modal-heading mb-0 mt-2">
   

              {!endDate
                ? "Activate Your Subscription"
                : localMode
                ? "Upgrade Your Subscription"
                : "Renew Your Subscription"}
            </p> */}

            <p className="modal-heading mb-0 my-2">
              Trial Activated Successfully
            </p>
          </div>

          <p
            className={`modal-description mt-2`}
            style={{ textAlign: "center" }}
          >
            Enjoy exploring the platform with limited access to selected modules
            designed to give you a sneak peek into what we offer.
            <br />
            Here’s what you can access during your trial: <strong>Investor Database, Resources</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrialActivationModal;
