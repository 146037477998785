import { useSubscription } from ".";
import CircleAvatar from "../components/atoms/CircleAvatar";
import "../components/molecules/Modal/LogoutModal/logout-modal.scss";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { confimationStyles } from "../assets/styles/toast";
import CloseButton from "../components/atoms/CloseButton";
import { setPeopleData } from "../store/slices/peopleSlice";

const SubscriptionBanner = () => {
  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;
  const dispatch = useDispatch()


  const staticData = {
    subscription_enable : true,
    is_trial : true,
    subs_days : 7,
    subs_start_date : new Date(),
    subs_end_date : new Date(new Date().setDate(new Date().getDate() + 5)),
    investor_database: true,
    resource : true
  }

  const { isSubscriptionExpired, isShow, setIsShow , setIsTrialActivationModalVisible} = useSubscription();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  if (!isSubscriptionExpired) return null;

  const trial_data_string = localStorage.getItem("trial_info");
  let trial_data;

  if (trial_data_string !== null) {
    // Only parse if the string is not null
    trial_data = JSON.parse(trial_data_string);
  }

  const endDate = userInfo?.subs_end_date || userInfo?.trial_end_date || trial_data?.subs_end_date || trial_data?.trial_end_date;

  const localMode =
    userInfo?.is_last_subs_mode_trial || trial_data?.is_last_subs_mode_trial ;

  const handleTrialActivation = () => {
    setIsShow(false);
    setIsTrialActivationModalVisible(true)

    const newUserInfo = {...userInfo, ...staticData }
      dispatch(setPeopleData(newUserInfo));
    
    
    toast.success("Your free trial has been activated successfully!", {
      style: confimationStyles,
      duration: 1000,
    });
  };

  return (
    <div
      className={`modal ${isSubscriptionExpired && isShow ? "active" : ""}`}
      // style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content">
        {!endDate && (
          // <button
          //   className="cursor-pointer"
          //   onClick={() => setIsShow(false)}
          //   aria-label="Close"
          //   style={{ position: "absolute", top: "10px", right: "10px" }}
          // >
          //   <CloseBtnIcom/>
          // </button>

          <div
            style={{ position: "absolute", top: "15px", right: "15px" , zIndex: 10}}
            className="cursor-pointer"
          >
            <CloseButton
              onClose={(e) => {
                e.stopPropagation();
                setIsShow(false);
              }}
            />
          </div>
        )}
        <div className="modal-body py-4">
          <div className="d-flex align-items-center justify-content-center flex-column gap-2">
            <CircleAvatar
              name={userInfo?.name}
              size="90px"
              fontSize="25px"
              color="#F4F4F4"
              borderRadius="12px"
              iconColor="#3D9BE9"
              border="1px solid #efefef"
            />
            <p className="modal-heading mb-0 mt-2">
              {/* Your {trial_data?.is_trial ? "Free Trial" : "Subscription"} has
              expired. */}

              {!endDate
                ? "Activate Your Subscription"
                : localMode
                ? "Upgrade Your Subscription"
                : "Renew Your Subscription"}
            </p>
          </div>

          {/* )} */}
          <p
            className={`modal-description ${localMode ? "mt-0" : "mt-2"}`}
            style={{ textAlign: "center" }}
          >
            {localMode ? (
              `Thank you for exploring PrimeAlpha. To continue accessing our investor database and powerful marketing tools, upgrade now and unlock the full potential of our platform. Leverage our features to supercharge your investor engagement.
`
            ) : (
              <>
                Subscribe now to regain full access to our investor database
                and advanced marketing tools.
                <br />{" "}
                <p>
                  <br />
                  Don’t miss out—activate your membership today!
                </p>
              </>
            )}
          </p>
          <div className="d-flex w-100 mt-3">
            {!endDate ? (
              <button
                className="btn btn-outline-dark"
                onClick={() => handleTrialActivation()}
                style={{ flex: "1 0 45%" }}
              >
                Activate Free Trial
              </button>
            ) : (
              <button
                className="btn btn-outline-dark"
                onClick={() => setIsShow(false)}
                style={{ flex: "1 0 45%" }}
              >
                Cancel
              </button>
            )}

            <button
              className="btn btn-primary mt-0"
              onClick={() => window.open(upgrade_link, "_blank")}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
            >
              {!endDate ? "Buy Now" : localMode ? "Upgrade Now" : "Renew Now"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBanner;
