import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import AccessBanner from "../../../contexts/AccessContext/AccessBanner";
import useScreenWidth from "../../../hooks/useScreenwidth";
import { dashboardRoute } from "../../../routes/index";
import { useSubscription } from "../../../SubscriptionContext";
import SubscriptionBanner from "../../../SubscriptionContext/SubscriptionBanner";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import Sidebar from "../../molecules/Sidebar";
import TrialBanner from "../../molecules/TrialBanner";
import styles from "./dashboard_layout.module.scss";

const DashboardLayout = () => {
  const [isActive, setIsActive] = useState(true);
  const screenWidth = useScreenWidth();

  const { handleUserClick, isSubscriptionExpired } = useSubscription();

  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);

  const location = useLocation();

  const toggleActiveState = () => {
    setIsActive(!isActive);
  };
  const sidebarRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const dashboardContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };
    const handleScroll = () => {
      if (screenWidth < 900) setIsActive(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenWidth]);

  const trial_data_string = localStorage.getItem("trial_info");
  let trial_data;

  if (trial_data_string !== null) {
    // Only parse if the string is not null
    trial_data = JSON.parse(trial_data_string);
  }

  useEffect(() => {
    const handleClickInside = (event: MouseEvent) => {
      if (!isSubscriptionExpired || !dashboardContentRef.current) return;

      if (!dashboardContentRef.current.contains(event.target as Node)) return;

      const targetElement = event.target as HTMLElement;

      // Helper function to check NavLink conditions
      const isValidNavLink = (element: HTMLElement | null) => {
        const navLinkIds = [
          "FAQ-link",
          "Home-link",
          "My Blogs-link",
          "Fund Directory-link",
          "Investors-link",
          "Requests-link",
        ];

        // if (userInfo?.role !== "Manager/Sponsor") {
        //   navLinkIds.push(
        //     ...[
        //       "Saved List-link",
        //       "Shared Content-link",
        //       "Requests-link",
        //       "Library-link",
        //     ]
        //   );
        // }

        const navLinkTexts = ["Help Center", "FAQ", "My Blogs",'Requests'];

        // if (userInfo?.role !== "Manager/Sponsor") {
        //   navLinkIds.push(...["Saved List", "Shared Content", "Requests"]);
        // }

        return (
          element &&
          (navLinkIds.includes(element.id) ||
            navLinkTexts.some((text) => element.textContent?.includes(text)))
        );
      };

      // Define container IDs to check
      const validContainerIds = [
        "FAQ-Container",
        "Help-Center-Container",
        "Company-Edit",
        "Investment-Preference-Container",
        "Company-Container",
        "MyBlogs-Container",
        "Product-Info-Containter",
        "Product-Form-Conatiner",
        "People-Edit-Container",
        "Work-Form-Container",
        "Work-View-Container",
        "Education-Form-Container",
        "Education-View-Container",
        "Profile-Container",
        "View-Library-Container",
        "Library-Container",
        "Thoughts-Form-Container",
      ];

      if (userInfo?.role !== "Manager/Sponsor") {
        validContainerIds.push(
          ...[
            "Saved-List-Container",
            "Shared-Content-Container",
            "Requests-Container",
            "Fund-Directory-Container",
            "Fund-Directory",
          ]
        );
      }

      // Check if target matches any valid container or NavLink
      const isInsideValidContainer =
        validContainerIds.some((id) => targetElement.closest(`#${id}`)) ||
        isValidNavLink(targetElement.closest("a"));

      if (!isInsideValidContainer) {
        handleUserClick(event); // Call the handler only for invalid clicks
      }
    };

    document.addEventListener("mousedown", handleClickInside);

    return () => {
      document.removeEventListener("mousedown", handleClickInside);
    };
  }, [isSubscriptionExpired, handleUserClick, userInfo]);

  return (
    <>
      <TrialBanner />

      <SubscriptionBanner />
      <AccessBanner/>
      <Navbar
        isActive={isActive}
        setIsActive={setIsActive}
        toggleActive={toggleActiveState}
        searchRequired={false}
        navbarRef={screenWidth < 900 ? navbarRef : null}
      />
      <div
        className={styles.dashboard_container}
        id="dashboard-content-container"
        ref={dashboardContentRef}
      >
        <Sidebar
          isActive={isActive}
          setIsActive={setIsActive}
          sidebarRef={screenWidth < 900 ? sidebarRef : null}
        />
        <div
          className={styles.dashboard_content_container}
          style={{
            minHeight:
              location.pathname ===
              "/dashboard/investors/investordatabase/Roadshow"
                ? "100vh"
                : "130vh",
          }}
        >
          <Outlet />
          {dashboardRoute}
        </div>
      </div>

      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </>
  );
};

export default DashboardLayout;
