import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useCreditCount } from ".";
import "../../components/molecules/Modal/LogoutModal/logout-modal.scss";

const CreditCountModal = () => {
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const { isShow, setIsShow, type, setType } = useCreditCount();

  // Ref for the modal content
  const modalContentRef = useRef();

  useEffect(() => {
    // Close the modal when clicking outside of the modal content
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        setIsShow(false);
      }
    };

    if (isShow) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isShow, setIsShow]);

  return (
    <div
      className={`modal ${isShow ? "active" : ""}`}
      style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content" ref={modalContentRef}>
        <div className="modal-body py-4">
          <div className="d-flex align-items-center justify-content-center flex-column gap-2">
            {/* <CircleAvatar
              name={userInfo?.name}
              size="90px"
              fontSize="25px"
              color="#F4F4F4"
              borderRadius="12px"
              iconColor="#3D9BE9"
              border="1px solid #efefef"
            /> */}
            <p className="modal-heading mb-0 mt-2">Credit Information</p>
          </div>

          <p
            className={`modal-description mt-2`}
            style={{ textAlign: "center" }}
          >
            You have successfully consumed 1 credit. Your remaining balance is X
            credits. Please make sure to monitor your credits to continue using
            the service seamlessly.
            {/* <a
              href="mailto:info@primealpha.com"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              info@primealpha.com
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreditCountModal;
