// import PropTypes from "prop-types";
// import type { FC, ReactNode } from "react";
// import { useEffect, useState } from "react";
// import { useCookies } from "react-cookie";
// import { useDispatch, useSelector } from "react-redux";
// import { Navigate, useLocation } from "react-router-dom";
// import { useAuthenticated } from "../hooks/useAuthenticated";
// import { fetchUserData } from "../store/slices/peopleSlice";
// import { useSubscription } from "../SubscriptionContext";

// interface AuthGuardProps {
//   children: ReactNode;
//   allowed?: any;
//   expired_subs_allowed?: false;
//   check_from_permission?: false;
// }

// const AuthGuard: FC<AuthGuardProps> = (props) => {
//   const { children, allowed, expired_subs_allowed, check_from_permission } =
//     props;
//   const auth = useAuthenticated();
//   const { isSubscriptionExpired } = useSubscription();

//   const location = useLocation();
//   const [cookies] = useCookies(["cuid"]);
//   const dispatch: any = useDispatch();
//   const userInfo = useSelector((state: any) => state.peopleData?.peopleData);

//   const conditions: any = {
//     resource: {
//       condition: "resource",
//       otherwise: userInfo?.investor_learn_more_link,
//     },
//     investment_library_education: {
//       condition: "investment_library_education",
//       otherwise: userInfo?.product_learn_more_link,
//     },
//     investor_database: {
//       condition: "investor_database",
//       otherwise: userInfo?.investor_learn_more_link,
//     },
//     manager_database: {
//       condition: "manager_database",
//       otherwise: userInfo?.product_learn_more_link,
//     },
//     is_admin: {
//       condition: "is_admin",
//       otherwise: "/",
//     },
//   };

//   const expired_condtion: any = {
//     "Investor/Manager/Sponsor": [
//       "saved-list",
//       "shared-content",
//       "requests",
//       "productdatabase",
//       "company",
//     ],
//     Investor: [
//       "saved-list",
//       "shared-content",
//       "requests",
//       "productdatabase",
//       "company",
//     ],
//   };

//   const [requestedLocation, setRequestedLocation] = useState<any>();
//   localStorage.setItem(
//     "emailrequest",
//     location?.pathname === "/dashboard/requests"
//       ? "email"
//       : location?.pathname === "/dashboard/requests/Sent"
//       ? "emailsent"
//       : ""
//   );

//   const accessConditionKey = Object.keys(conditions).find((key) =>
//     allowed?.includes(key)
//   );

//   const accessCondition = accessConditionKey
//     ? conditions[accessConditionKey]
//     : null;

//   useEffect(() => {
//     dispatch(fetchUserData(cookies.cuid));
//   }, [dispatch, cookies.cuid, accessConditionKey]);

//   if (!auth) {
//     if (location.pathname !== requestedLocation) {
//       setRequestedLocation(location.pathname);
//     }

//     return <Navigate to="/auth" />;
//   }

//   // if (isSubscriptionExpired) {
//   //   return <Navigate to="/dashboard" />;
//   // }

//   if (isSubscriptionExpired) {
//     if (location.pathname !== "/dashboard" && !expired_subs_allowed) {
//       const userRole = userInfo?.role;
//       const roleConditions = expired_condtion?.[userRole] || [];

//       const isPathAllowed = roleConditions.some((condition: any) =>
//         location.pathname.includes(condition)
//       );
//       if (check_from_permission && isPathAllowed) {
//         return <>{children}</>;
//       } else {
//         return <Navigate to="/dashboard" />;
//       }
//     } else {
//       return <>{children}</>;
//     }
//   } else if (allowed?.length) {
//     if (userInfo?.has_master_access || userInfo[accessCondition?.condition]) {
//       return <>{children}</>;
//     } else {
//       window.location.href = accessCondition?.otherwise;
//     }
//   } else {
//     if (requestedLocation && location.pathname !== requestedLocation) {
//       setRequestedLocation(null);
//       return <Navigate to={requestedLocation} />;
//     }
//   }
//   return <>{children}</>;
// };

// AuthGuard.propTypes = {
//   children: PropTypes.node,
// };

// export default AuthGuard;

import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthenticated } from "../hooks/useAuthenticated";
import { fetchUserData } from "../store/slices/peopleSlice";
import { useSubscription } from "../SubscriptionContext";

interface AuthGuardProps {
  children: ReactNode;
  allowed?: any;
  expired_subs_allowed?: false;
  check_from_permission?: false;
  permission_to_check?: any;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const {
    children,
    allowed,
    expired_subs_allowed,
    check_from_permission,
    permission_to_check,
  } = props;

  console.log("all", allowed);
  const auth = useAuthenticated();
  const { isSubscriptionExpired } = useSubscription();

  const location = useLocation();
  const [cookies] = useCookies(["cuid"]);
  const dispatch: any = useDispatch();
  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);

  const conditions: any = {
    resource: {
      condition: "resource",
      otherwise: userInfo?.investor_learn_more_link,
    },
    investment_library_education: {
      condition: "investment_library_education",
      otherwise: userInfo?.product_learn_more_link,
    },
    investor_database: {
      condition: "investor_database",
      otherwise: userInfo?.investor_learn_more_link,
    },
    manager_database: {
      condition: "manager_database",
      otherwise: userInfo?.product_learn_more_link,
    },
    is_admin: {
      condition: "is_admin",
      otherwise: "/",
    },
  };

  const expired_condtion: any = {
    "Investor/Manager/Sponsor": [
      "saved-list",
      "shared-content",
      "requests",
      "productdatabase",
      "company",
    ],
    Investor: [
      "saved-list",
      "shared-content",
      "requests",
      "productdatabase",
      "company",
    ],
  };

  const [requestedLocation, setRequestedLocation] = useState<any>();
  localStorage.setItem(
    "emailrequest",
    location?.pathname === "/dashboard/requests"
      ? "email"
      : location?.pathname === "/dashboard/requests/Sent"
      ? "emailsent"
      : ""
  );

  // const accessConditionKey = Object.keys(conditions).find((key) =>
  //   allowed?.includes(key)
  // );

  // const accessCondition = accessConditionKey
  //   ? conditions[accessConditionKey]
  //   : null;

  useEffect(() => {
    dispatch(fetchUserData(cookies.cuid));
  }, [dispatch, cookies.cuid]);

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth" />;
  }

  // if (isSubscriptionExpired) {
  //   return <Navigate to="/dashboard" />;
  // }

  if (allowed?.length) {
    return <>{children}</>;
  } else if (permission_to_check) {
    if (userInfo?.[permission_to_check]) {
      return <>{children}</>;
    } else {
      return <Navigate to="/dashboard" />;
    }
  } else {
    if (requestedLocation && location.pathname !== requestedLocation) {
      setRequestedLocation(null);
      return <Navigate to={requestedLocation} />;
    }
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
